import React, { useEffect, useState, useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import api from "../../services/api";

import Input from "../Form/input";

const optionsDefault = {
  frm_action: "https://sis.getrak.com/qualis/acesso/index",
  frm_login: "login",
  frm_password: "senha",
  frm_nm_token: "_token",
  frm_token: "2re5405FcSX6mBNU7V5u8J6oqkYFvaBsBU5hRhNz",
  frm_url_forgot_pass: "https://sis.getrak.com/qualis/acesso/recuperacao",
};

export default function RastreioForm() {
  const formRef = useRef(null);
  const [token, setToken] = useState();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [alert, setAlert] = useState(false);
  const [panel, setPanel] = useState("welcome");
  const [register, setRegister] = useState();
  const [optionsForm, setOptionsForm] = useState(optionsDefault);

  useEffect(() => {
    async function fetchData() {
      const response = await api
        .post(`/auth/authenticate`, {
          email: process.env.REACT_APP_USER_LOGIN,
          password: process.env.REACT_APP_USER_PASS,
        })
        .then((result) => {
          let data = result.data;
          return data;
        })
        .catch((error) => {
          return error;
        });
      setToken(response.token);
    }
    fetchData();
  }, []);

  const findPlatform = async (register) => {
    let result = await api
      .post(
        "/equipment/check",
        { register },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        return result.data;
      })
      .catch((error) => console.log(error));
    return result;
  };

  async function handleSubmit(data, { reset }) {
    try {
      const schema = Yup.object().shape({
        register: Yup.string().required("O campo é obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current.setErrors({});

      let find = await findPlatform(data.register);
      
      if (find.error) {
        setAlert(true);
      }

      if (find.equipment === "GETRAK") {
        setPanel("getrak");
        setRegister(data.register);
      }
      if (find.equipment === "NAVIXY") {
        setPanel("navixy");
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erroMessages = {};

        err.inner.forEach((error) => {
          erroMessages[error.path] = error.message;
        });
        formRef.current.setErrors(erroMessages);
      }
    }
  }

  const getBtnDisabled = (e) => {
    let qtd = e.length;
    if (qtd >= 11) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const Alert = () => {
    return (
      <div className="alert alert-dark" role="alert">
        Ops! Não encontramos o seu registro ou sua conta não foi ativada. Para
        mais informações <a href="/#contato">fale com a gente</a>.
      </div>
    );
  };

  const Welcome = () => {
    return (
      <React.Fragment>
        <div className="mb-5">
          <h5 className="h3">Informe seu CPF/CNPJ</h5>
          <p className="text-muted mb-0">
            Com esse dado vamos direcioná-lo para a melhor plataforma.
          </p>
        </div>
        <Form
          ref={formRef}
          className="login-signup-form"
          onSubmit={handleSubmit}
        >
          {alert ? <Alert /> : ""}
          <div className="form-group">
            <label className="pb-1">CPF/CNPJ</label>
            <div className="input-group input-group-merge">
              <div className="input-icon">
                <span className="ti-user color-secondary"></span>
              </div>
              <Input
                type="text"
                name="register"
                id="register"
                className="form-control"
                placeholder="Seu CPF/CNPJ"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
            disabled={btnDisabled ? "disabled" : ""}
          >
            Próximo
          </button>
        </Form>
      </React.Fragment>
    );
  };

  const Getrak = () => {
    return (
      <React.Fragment>
        <div className="mb-5">
          <h5 className="h3">Localize o seu veículo</h5>
          <p className="text-muted mb-0">
            Informe os dados abaixo para ter acesso a localização do seu
            veículo.
          </p>
        </div>
        <form
          className="login-signup-form"
          action={optionsForm.frm_action}
          method="post"
          target="_blank"
        >
          <div className="form-group">
            <label className="pb-1">Usuário</label>
            <div className="input-group input-group-merge">
              <div className="input-icon">
                <span className="ti-user color-secondary"></span>
              </div>
              <input
                type="text"
                name={optionsForm.frm_login}
                defaultValue={register}
                id="login"
                className="form-control"
                placeholder="Usuário"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label className="pb-1">Senha</label>
              </div>
              <div className="col-auto">
                <a
                  href={optionsForm.frm_url_forgot_pass}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form-text small text-muted"
                >
                  Esqueceu a senha?
                </a>
              </div>
            </div>
            <div className="input-group input-group-merge">
              <div className="input-icon">
                <span className="ti-lock color-secondary"></span>
              </div>
              <input
                type="password"
                name={optionsForm.frm_password}
                id="senha"
                className="form-control"
                placeholder="Senha"
                required
              />
            </div>
          </div>
          <input
            name={optionsForm.frm_nm_token}
            value={optionsForm.frm_token}
            type="hidden"
          />
          <button
            type="submit"
            className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
          >
            Entrar
          </button>
        </form>
      </React.Fragment>
    );
  };

  const Navixy = () => {
    return (
      <React.Fragment>
        <div className="mb-5">
          <h5 className="h3">Cliente localizado</h5>
          <p className="text-muted mb-0">
            Clique em AVANÇAR para ter acesso à nossa plataforma.
          </p>
        </div>
        <a
          href="https://rastreamento.grupoqualis.com.br/#/login"
          className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
          target="_blank"
        >
          Avançar
        </a>
      </React.Fragment>
    );
  };

  const currentPanel = (panel) => {
    switch (panel) {
      case "getrak":
        return <Getrak />;
        break;
      case "navixy":
        return <Navixy />;
        break;
      default:
        return <Welcome />;
        break;
    }
  };

  return (
    <React.Fragment>
      <section
        className="hero-section ptb-100 background-img-header full-screen"
        style={{
          backgroundImage: "url('img/bg-2.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            <div className="col-md-7 col-lg-6">
              <div className="hero-content-left text-white">
                <h1 className="text-white">Seja bem vindo!</h1>
                <p className="lead">
                  Estamos vigilantes 24h por dia para cuidar, monitorar e lhe
                  assessorar, seja ele carro, moto, caminhões, embarcações ou
                  mesmo pessoas.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  {currentPanel(panel)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
