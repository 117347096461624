import React, { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../Form/input";
import Select from "../Form/Select";

import api from "../../services/api";

export default function Simulator() {
  const formRef = useRef(null);
  const [token, setToken] = useState();
  const [viewResult, setviewResult] = useState(false);
  const [viewForm, setviewForm] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await api
        .post(`/auth/authenticate`, {
          email: process.env.REACT_APP_USER_LOGIN,
          password: process.env.REACT_APP_USER_PASS,
        })
        .then((result) => {
          let data = result.data;
          return data;
        })
        .catch((error) => {
          return error;
        });
      setToken(response.token);
    }
    fetchData();
  }, []);

  const options = [
    { value: "carro", label: "Carro" },
    { value: "moto", label: "Moto" },
    { value: "caminhão", label: "Caminhão" },
    { value: "jetski", label: "JetSki" },
    { value: "embarcação", label: "Embarcação" },
  ];

  const getSimulator = async (name, email, phone, vehicle) => {
    let result = await api
      .post(
        "/client/simulator",
        { name, email, phone, vehicle },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        return result.data;
      })
      .catch((error) => console.log(error));
    return result;
  };

  async function handleSubmit(data, { reset }) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("O nome é obrigatório"),
        email: Yup.string()
          .email("Informe um e-mail válido")
          .required("O e-mail é obrigatório"),
        phone: Yup.string().required("O telefone é obrigatório"),
        vehicle: Yup.string().required("O veículo é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current.setErrors({});

      setviewResult(true);
      setviewForm(false);

      await getSimulator(
        data.name,
        data.email,
        data.phone,
        data.vehicle
      );

      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erroMessages = {};

        err.inner.forEach((error) => {
          erroMessages[error.path] = error.message;
        });
        formRef.current.setErrors(erroMessages);
      }
    }
  }

  return (
    <div className="row justify-content-center">
      {/* form */}
      {viewForm && (
        <Form ref={formRef} className="contact-us-form" onSubmit={handleSubmit}>
          <h5>Inform os dados abaixo</h5>
          <div className="row">
            <div className="col-sm-12 col-12">
              <div className="form-group">
                <Select
                  name="vehicle"
                  id="vehicle"
                  placeholder="Escolha o veiculo"
                  required="required"
                  options={options}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-12">
              <div className="form-group">
                <Input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  placeholder="Nome"
                  required="required"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-12">
              <div className="form-group">
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder="Telefone"
                  required="required"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-12">
              <div className="form-group">
                <Input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="E-mail"
                  required="required"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-12 mt-3 text-center">
              <button type="submit" className="btn solid-btn">
                Ver minha cotação
              </button>
            </div>
          </div>
        </Form>
      )}
      {/* result */}
      {viewResult && (
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md ">
            <div className="card text-center single-pricing-pack popular-price">
              <div className="pt-4">
                <h5>Chip GSM/M2M</h5>
              </div>
              <div className="pricing-img mt-4">
                <img
                  src="img/basic.svg"
                  alt="pricing img"
                  className="img-fluid"
                />
              </div>
              <div className="card-header py-4 border-0 pricing-header">
                <div className="h1 text-center mb-0">
                  <span className="price font-weight-bolder">R$ 69,90</span>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                  <li>Chip GSM/M2M (Uniband)</li>
                  <li>Controle por aplicativo (Android e IOS)</li>
                  <li>Central de monitoramento 24h</li>
                  <li>Cerca virtual</li>
                  <li>Campo de força</li>
                  <li>Aviso de ignição</li>
                  <li>Histórico de localização</li>
                  <li>Localização em tempo real</li>
                </ul>

                <a
                  href="https://wa.me/559233472776?text=Olá,%20eu%20fiz%20uma%20cotação%20do%20meu%20veículo%20e%20gostaria%20de%20saber%20mais%20detalhes!"
                  className="btn mb-3 outline-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-12 mt-3 text-center">
            <button
              className="btn solid-btn"
              onClick={() => {
                setviewForm(true);
                setviewResult(false);
              }}
            >
              Quero fazer novamente
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
