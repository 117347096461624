import React, { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../Form/input";
import TextArea from "../Form/textarea";

import api from "../../services/api";

export default function ContactForm() {
  const formRef = useRef(null);
  const [token, setToken] = useState();
  const [contactBtnText, setcontactBtnText] = useState("Enviar");

  useEffect(() => {
    async function fetchData() {
      const response = await api
        .post(`/auth/authenticate`, {
          email: process.env.REACT_APP_USER_LOGIN,
          password: process.env.REACT_APP_USER_PASS,
        })
        .then((result) => {
          let data = result.data;
          return data;
        })
        .catch((error) => {
          return error;
        });
      setToken(response.token);
    }
    fetchData();
  }, []);

  const getContact = async (name, email, phone, subject, message) => {
    let result = await api
      .post(
        "/client/contact",
        { name, email, phone, subject, message },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        return result.data;
      })
      .catch((error) => console.log(error));
    return result;
  };

  async function handleSubmit(data, { reset }) {
    try {
      setcontactBtnText("Enviando...");
      const schema = Yup.object().shape({
        name: Yup.string().required("O nome é obrigatório"),
        email: Yup.string()
          .email("Informe um e-mail válido")
          .required("O e-mail é obrigatório"),
        phone: Yup.string().required("O telefone é obrigatório"),
        subject: Yup.string().required("O assunto é obrigatório"),
        message: Yup.string().required("A mensagem é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current.setErrors({});

      await getContact(
        data.name,
        data.email,
        data.phone,
        data.subject,
        data.message
      );
      setcontactBtnText("Enviado!");

      setInterval(() => {
        setcontactBtnText("Enviar nova mensagem");
      }, 5000);

      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erroMessages = {};

        err.inner.forEach((error) => {
          erroMessages[error.path] = error.message;
        });
        formRef.current.setErrors(erroMessages);
      }
    }
  }

  return (
    <div className="justify-content-center">
      {/* form */}
      <Form ref={formRef} className="contact-us-form" onSubmit={handleSubmit}>
        <h5>Conte-me um pouco sobre você</h5>
        <div className="row">
          <div className="col-sm-6 col-12">
            <div className="form-group">
              <Input
                type="text"
                name="name"
                id="name"
                className="form-control"
                placeholder="Nome"
                required="required"
              />
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="form-group">
              <Input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="E-mail"
                required="required"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-12">
            <div className="form-group">
              <Input
                type="text"
                name="phone"
                id="phone"
                className="form-control"
                placeholder="Telefone"
                required="required"
              />
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="form-group">
              <Input
                type="text"
                name="subject"
                id="subject"
                className="form-control"
                placeholder="Assunto"
                required="required"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <TextArea
                type="text"
                name="message"
                id="message"
                className="form-control"
                rows="7"
                cols="25"
                placeholder="Mensagem"
                required="required"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3">
            <button type="submit" className="btn solid-btn">
              {contactBtnText}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
