export const TOKEN_KEY = "@agile-Token";
export const USER_ACCESS = "@agile-User";

export const isAuthenticated = () => {
  if (localStorage.getItem(TOKEN_KEY) !== null) return true
  return false;
};

export const getToken = () => {
  let token = localStorage.getItem(TOKEN_KEY)
  return token;
};

export const setLogin = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setUser = (data) => {
  localStorage.setItem(USER_ACCESS, JSON.stringify(data));
};

export const getUser = () => {
  let user = localStorage.getItem(USER_ACCESS);
  const uset_parse = JSON.parse(user);
  return uset_parse;
};

export const setLogout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_ACCESS);
};