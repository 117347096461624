import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <span className="badge badge-primary badge-pill">
                  Nossos serviços
                </span>
                <h2 className="mt-4">Muito mais que um serviço de rastreamento veicular.</h2>
                <p className="mb-4 lh-190">
                  Além do rastreamento veicular, oferecemos vários serviços adicionais.
                  Sendo assim, o serviço mais completo do Brasil.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Assistência 24 Horas (Reboque, Socorro elétrico, Socorro mecânico)
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Assistência residencial
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Intermediação para Seguro de Auxílio invalidez
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Intermediação para Seguro de Vida
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Intermediação para Assistência funeral
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/index/vector/03.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
