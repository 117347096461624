import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={
              "footer-top background-img-2 " +
              (this.props.noSubscription ? "py-5" : "pt-350 pb-5")
            }
            style={{
              backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="img/logo_qualis.png"
                      alt="footer logo"
                      width="120"
                      className="img-fluid mb-3"
                    />
                    <p>
                      O Grupo Qualis é uma empresa genuinamente brasileira,
                      localizada na região norte, sul e sudeste do Brasil. O
                      Grupo à qual pertence, teve sua origem no Rio de Janeiro,
                      em meados de 2013. Até os dias de hoje, tem se pautado em
                      uma premissa principal, que carrega no próprio nome. A
                      Qualidade! Assim, somos um conjunto de pessoas, formados
                      por seus colaboradores, dirigentes, acionistas,
                      consultores, prestadores de serviços e parceiros de
                      negócios que, individual ou coletivamente, se norteiam
                      valores emanados na corporação.
                    </p>

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a
                            href="https://www.facebook.com/ogrupoqualis/"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Facebook"
                          >
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.instagram.com/ogrupoqualis/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Instagram"
                          >
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Aplicativo</h5>
                    <p className="text-app">
                      Aguarde. O novo App da Qualis está chegando.
                    </p>
                    <p className="text-info">
                      Em breve atualizaremos nosso aplicativo. Design e
                      tecnologia juntas para oferecer uma experiência única aos
                      nossos clientes. 
                    </p>
                    <div className="row">
                      <div className="col-lg-5 mb-4 mb-lg-0">
                        <img
                          className="icon-app"
                          src="./img/apple-store-button.png"
                        />
                      </div>
                      <div className="col-lg-5 mb-4 mb-lg-0">
                        <img
                          className="icon-app"
                          src="./img/google-play-buttom.png"
                        />
                      </div>
                    </div>

                    <h5 className="mb-2 text-white">WhatsApp</h5>
                    <p className="text-phone">(92) 33472776</p>
                    <p className="text-info">
                      Quer conversar com a gente pelo whats? Podemos trocar
                      mensagens de seg a sáb, das 8h às 18h, exceto em feriados.
                    </p>
                  </div>
                </div>

                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-2 text-white">Acesso rápido</h5>
                    <p className="text-info">
                      Aproveite os serviços online da Qualis. Aqui você pode
                      localizar seu veículo e emitir a segunda via do boleto
                      para pagamento. ​
                    </p>
                    <ul className="list-unstyled support-list mb-3">
                      <li className="mb-2 d-flex align-items-center">
                        <a href="/rastreio">
                          <span className="ti-location-pin mr-2"></span>{" "}
                          Localize seu veículo
                        </a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <a href="/segunda-via">
                          <span className="ti-receipt mr-2"></span> 2ª via do
                          boleto
                        </a>
                      </li>
                    </ul>

                    <h5 className="mb-2 text-white">Nossa Sede</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        Rua Amazonas Cavalcante,
                        <br />
                        29, Parque 10 de Novembro,
                        <br />
                        Manaus, AM.
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        <a href="tel:+61283766284"> (92) 98405-8455</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:administrativo@grupoqualis.com.br">
                          {" "}
                          administrativo@grupoqualis.com.br
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-2 text-white">
                      Central de apoio roubo e furto
                    </h5>
                    <p className="text-phone">0800 590 1113 </p>
                    <p className="text-info">
                      Atendimento 24h por dia, 7 dias na semana. ​
                    </p>

                    <h5 className="mb-2 text-white">Ouvidoria</h5>
                    <p className="text-phone">(92) 98436-0487 </p>
                    <p className="text-info">
                      Seu problema ainda não foi resolvido através dos nosso
                      canais de atendimento? Ligue-nos: segunda a sexta, de 8h
                      as 17h, exceto em feriados. ​
                    </p>

                    <h5 className="mb-2 text-white">Comercial</h5>
                    <p className="text-phone">(92) 98405-8455 </p>
                    <p className="text-info">
                      Atendimento pelo telefone de seg a sáb, das 8h às 17h,
                      exceto em feriados. ​
                    </p>

                    <h5 className="mb-2 text-white">Adminstração</h5>
                    <p className="text-phone">(92) 3347-2776 </p>
                    <p className="text-info">
                      Atendimento pelo telefone de seg a sáb, das 8h às 17h,
                      exceto em feriados. ​
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    2020. Grupo Qualis. Todos os direitos reservados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
