import React from "react";

export default function SegundaViaForm() {
  return (
    <React.Fragment>
      <section
        className="hero-section ptb-100 background-img-header full-screen"
        style={{
          backgroundImage: "url('img/bg-1.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            <div className="col-md-7 col-lg-6">
              <div className="hero-content-left text-white">
                <h1 className="text-white">Esta é sua área!</h1>
                <p className="lead">
                  Para pagamentos, 2º vias de boletos, informações e outras facilidades.
                  </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <div className="mb-5">
                    <h5 className="h3">Área do cliente</h5>
                    <p className="text-muted mb-0">
                      Informe os dados abaixo para ter acesso ao painel do cliente.
                      </p>
                  </div>

                  <form
                    name="frmLogin"
                    action="https://sgr.hinova.com.br/sgr/areaSGRV2/login.action.php"
                    method="post"
                    className="login-signup-form"
                    target="_blank"
                  >
                    <div className="form-group">
                      <label className="pb-1">Usuário</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-user color-secondary"></span>
                        </div>
                        <input
                          type="text"
                          name="dfsCpf"
                          id="dfsCpf"
                          className="form-control"
                          placeholder="CPF/CNPJ"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="pb-1">Senha</label>
                        </div>
                      </div>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-lock color-secondary"></span>
                        </div>
                        <input
                          type="password"
                          name="dfsSenha"
                          id="dfsSenha"
                          className="form-control"
                          placeholder="Senha"
                          required
                        />
                      </div>
                    </div>
                    <input name="dfsDb" value="sgrv2_total_24h" type="hidden" />
                    <button
                      type="submit"
                      className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    >
                      Entrar
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
