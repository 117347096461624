import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Rastreio from "../themes/rastreio";
import SegundaVia from "../themes/segunda-via";
import Theme from "../themes/theme";

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme} />
            <Route exact path="/rastreio" component={Rastreio} />
            <Route exact path="/segunda-via" component={SegundaVia} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
