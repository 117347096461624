import React from "react";

class PqSerNossoCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/index/vector/02.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    Segurança <br />
                    <span>Porque ser nosso cliente</span>
                  </h2>
                  <p>
                    Porque sabemos o valor do patrimônio de nossos clientes.
                    Entendemos que aquilo que lhe custou anos de suor e muito trabalho
                    não deveria ser tirado de suas mãos. Acreditamos que a segurança
                    é fundamental para termos mais qualidade de vida.
                  </p>
                  <p>
                    E falando em qualidade, falou em Qualis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default PqSerNossoCliente;
