import React, { Component } from 'react';

import Simulator from './simulator';

import _data from "../../data";

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="cote-gratis"
            className={"package-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : 'background-shape-right')} >
            <div className="container">
              {/* title */}
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {this.props.showTitle && this.props.showTitle === true && (
                      <span className="badge badge-primary badge-pill">
                        Cote grátis
                      </span>
                    )}
                    <h2>
                      A melhor experiência em Rastreamento Veicular <br />
                      <span>Escolha a melhor</span>
                    </h2>
                    <p className="lead">
                      Com apenas 4 campos você evita uma dor de cabeça com
                      a segurança que cabe no seu bolso.
                    </p>
                  </div>
                </div>
              </div>
              {/* form */}
              <Simulator />   
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
