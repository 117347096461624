import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="nosso-diferencial" className={"feature-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <span className="badge badge-primary badge-pill">
                    Nosso diferencial
                  </span>
                  <h2>
                    Por que a Qualis? <br />
                    <span>Repleto de recursos, com o melhor custo/benefício!</span>
                  </h2>
                  <p>
                    Preparamos a melhor configuração dos equipamentos mais completos do mercado até o momento.
                    Possuimos tecnologia <span className="mark-text">GSM/M2M</span> como também <span className="mark-text">100% satelital</span>.
                  </p>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-face-smile"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Em tempo real</h5>
                    <p className="mb-0">
                      Fique sabendo a qualquer momento onde se encontra seu veículo,
                      um familiar ou o que você deseja rastrear. <span className="mark-text"> Possuimos a disponibilidade
                      de atualização de posição de até 5 em 5 segundos.</span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-vector"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Histórico de localização</h5>
                    <p className="mb-0">
                      Acompanhe o trajeto percorrido em um determinado dia e faixa
                      de horário, selecionando preferências.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-headphone-alt"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Adição de cercas virtuais</h5>
                    <p className="mb-0">
                      Determine perímetros no mapa e receba alertas toda vez que
                      seu rastreador entrar ou sair da área cadastrada.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{ zIndex: 10 }}>
                  <img
                    alt="placeholder"
                    src="img/index/vector/04.png"
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-layout-media-right"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Site ou Aplicativo?</h5>
                    <p className="mb-0">
                      Acompanhe seus rastreados através do acesso web
                      ou via aplicativo para smartphones e tablets.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-layout-cta-right"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Configuração automatizada</h5>
                    <p className="mb-0">
                      Configure automaticamente o seu dispositivo rastreador
                      dedicado, evitando o envio de comandos complexos.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-palette"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Feito para sua empresa</h5>
                    <p className="mb-0">
                      Não importa o tamanho da sua frota, fiscalização e controle
                      desde o primeiro veículo, feito para ser utilizado no dia
                      a dia do seu negócio!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
