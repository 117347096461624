import React from "react";
import { connect } from "react-redux";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="hero-section pt-100 background-img-header"
        style={{
          backgroundImage: "url('img/index/bg/01.png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}>
        <div className="container">
            <div className="row align-items-center justify-content-between">
                <div className="col-md-6 col-lg-6">
                    <div className="hero-content-left text-white mt-5">
                        <h1 className="text-white">Muito mais que um serviço de <span>rastreamento veicular</span>.</h1>
                        <p className="lead">
                            Depois da conquista do seu carro, moto ou embarcação, garantir sua segurança é fundamental. 
                            E isso é mais fácil e barato que você imagina.
                        </p>

                        <a href="#cote-gratis" className="btn app-store-btn page-scroll">Descubra como</a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-5">
                    <div className="hero-animation-img">
                        <img src="img/index/02.png" alt="img" className="img-fluid" />
                    </div>
                </div>
            </div>
            
            <div className="row">
                <ul className="list-inline counter-wrap">
                    <li className="list-inline-item">
                        <div className="single-counter2 text-center">
                            <span>Preço baixo</span>
                            <h6>Valor justo e melhor custo-benefício, independente do modelo.</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter2 text-center">
                            <span>Em tempo real</span>
                            <h6>Fácil e rápido. Rastreie seu veículo em tempo real pelo App.</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter2 text-center">
                            <span>Atendimento rápido</span>
                            <h6>Tenha um suporte diferenciado e ágil no momento em que mais precisa.</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter2 text-center">
                            <span>Feito para sua empresa</span>
                            <h6>Não importa o tamanho da sua frota. Controle desde o primeiro veículo.</h6>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
        </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
