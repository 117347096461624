import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
//import Select from 'react-select';

import ReactSelect from 'react-select';

export default function Select({ name, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name)    

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'state.value',
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                } else {
                    if (!ref.state.value) {
                        return '';
                    }
                    return ref.state.value.value;
                }
            },
        });

    }, [fieldName, registerField, rest.isMulti]);

    return (
        <div>
            <ReactSelect
                defaultValue={defaultValue}
                ref={inputRef}
                classNamePrefix="react-select"
                {...rest}
            />                                  
            {error && <span>{error}</span>}
        </div>

    )
}