import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection2";
import FeatureSections from "../components/Features";
import Qualis from "../components/Features/pq_ser_nosso_cliente";
import PromoSection from "../components/PromoSection/Promo2";
import AboutSection from "../components/AboutUs/AboutUs2";
import DownloadSection from "../components/Download";
import FeatureSection from "../components/Features/Feature2";
import PricingSection from "../components/Pricing";
import Testimonial from "../components/Testimonial";
import FaqSection from "../components/Faq";
import ContactSection from "../components/Contact";
import SubscribeSection from "../components/Subscribe";
import FooterSection from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSection />
          <FeatureSections />
          <DownloadSection />
          <Qualis />
          <PromoSection />
          <AboutSection />
          <FeatureSection />          
          <PricingSection showTitle={true} hideFaq={true} />
          <Testimonial />          
          <FaqSection />
          <ContactSection />          
        </div>
        <SubscribeSection />
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default Theme;
