import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="conheca-qualis" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/index/vector/01.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    Uma empresa diferente <br />
                    <span>O grupo Qualis</span>
                  </h2>
                  <p>
                    Hoje, somos uma empresa de rastreamento de veículos que nasceu
                    com a missão de proporcionar mais segurança e tranquilidade para
                    você que deseja proteger seu patrimônio contra roubos ou furtos.
                  </p>
                  <p>
                    Estamos vigilantes 24h por dia para cuidar, monitorar e lhe assessorar,
                    seja ele carro, moto, caminhões, embarcações ou mesmo pessoas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
