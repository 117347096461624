import React from "react";

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="download-section pt-100 background-img"
          style={{
            backgroundImage: "url('img/index/bg_01.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="download-content text-white pb-100">
                  <h2 className="text-white">
                    O que oferecemos?
                  </h2>
                  <p className="lead">
                    Nosso serviço de rastreamento em tempo real mostra a exata localização 
                    do seu veículo, facilitando sua rápida localização na hora e momento que 
                    desejar. Oferecemos soluções para você que busca mais segurança para sua 
                    família e seu patrimônio, pois sabemos o quanto valem suas conquistas. 
                    A Qualis é para você que deseja proteger seus bens de valor com eficiência, 
                    facilidade e segurança, com um preço que cabe no seu bolso.
                  </p>

                  <div className="download-btn">                    
                    <a href="#cote-gratis" className="btn app-store-btn page-scroll"> Cote grátis </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/index/01.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Download;
