import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Textarea({ name, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, registerField]);

    return (
        <div>
            <textarea ref={inputRef} {...rest} ></textarea>
            {error && <span>{error}</span>}
        </div>

    )
}