module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Why small business owners love AppCo?",
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$29",
        license: "Basic",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "2 months technical support",
          "2+ profitable keyword"
        ]
      },
      {
        price: "$50",
        license: "Popular",
        isFeatured: true,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "6 months technical support",
          "30+ profitable keyword"
        ]
      },
      {
        price: "$120",
        license: "Extended",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "1 year technical support",
          "70+ profitable keyword"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Marcelo",
        company: "Frotista com 9 veículos monitorados",
        image: "img/client-2.jpg",
        comment:
          "Com enorme satisfação, recomendo a empresa para monitoramento veicular. Comecei com apenas um, hoje tenho 9 veículos monitorados, com clareza e presteza em todo o atendimento que precisei. Tive a infelicidade de ter um veículo roubado antes de conhecer a empresa, agora durmo é acordo tranquilo pois sei onde estão cada um dos meu veículos. Recomendo a empresa para todos e agradeço o apoio prestado no monitoramento das motos e dos carros. Meu muito obrigado."
      }
    ]
  },
  contact: {
    title: "Quero saber mais",
    description:
      "Tire todas as suas dúvidas e encontre motivos de sobra para proteger o seu bem com a Qualis.",

    office: "Sede",
    address: "Rua Amazonas Cavalcante, 29, Parque 10 de Novembro, Manaus, AM.",
    phone: "(92) 98405-8455",
    email: "administrativo@grupoqualis.com.br"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
